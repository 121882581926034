import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import {graphql, useStaticQuery} from "gatsby";
import Contact from "components/common/form"
import Reviews from "components/common/reviews/reviews";
import Contacto from "components/common/contacto/Contacto";
import theme from "styles/style";
import Breadcrumb from "components/common/breadcrumbs/Breadcrumb";
import { SEO } from 'components/common/SEO';
import {CameraBG} from 'components/home/Content/occss-home';


const ContactoPage = () => {
    const data = useStaticQuery(graphql`
        query contactPage {
            site {
              siteMetadata {
                title
                foundingDate
                contact{
                    email
                    phone
                }
                socialLinks{
                    linkedin
                    instagram
                    youtube
                }
                address {
                streetAddress
                zipCode
                city
                }
              }
            }
          }
    `);

    const start = createRef(null);
    const block_1 = createRef(null);
    const end = createRef(null);

    const adjust = {
        start: { left: 10, color: `${theme.orange}`, size: 150, animation: false, center: false},
        block_1: { left: 50, color: `${theme.blue}`, size: 200, animation: true, center: false},    
    };      

    return (
        <>
        <SEO
              title = "Contactar con Obtura Audiovisual"
              description= "¿Quieres preguntarnos algo? En esta página tienes disponibles nuestras principales vías de contacto."
              canonical = 'contacto'
              robots = 'index, follow'
          />
        <Layout start={start} block_1={block_1} end={end} adjust={adjust}>
        <div className="container">
                <section className="grid-x2 margin-top--x2" ref={start}>
                    <div className="margin">
                        <h1 className="putDot">Contáctanos</h1>
                        <address>
                            <a href={`mailto:${data.site.siteMetadata.contact.email}`} className={"a-contact-link"} target={"_blank"} rel={"noreferrer noopener nofollow"}>{data.site.siteMetadata.contact.email}</a><br/>
                            <a href={`tel:${data.site.siteMetadata.contact.phoneNoSpacing}`} className={"a-contact-link"} target={"_blank"} rel={"noreferrer noopener nofollow"}>{data.site.siteMetadata.contact.phone}</a><br/>
                            {data.site.siteMetadata.address.streetAddress}, {data.site.siteMetadata.address.zipCode}. <br/>
                            {data.site.siteMetadata.address.city}.
                        </address>
                    </div>
                    <div className="padding">
                    <Contact/>
                    </div>
                </section>
            </div>
            <CameraBG className={"padding-xl"}>
                <div className={"container margin-l"} ref={block_1}>
                    <h2 className={"h1 text-center putDot"}>
                        PODEMOS AYUDARTE CON TU ESTRATEGIA AUDIOVISUAL
                    </h2>
                </div>
                <div className="container margin-l">
                    <section className="grid-x2">
                        <Reviews />
                    </section>
                </div>
            </CameraBG>
            <div className={"container margin-l"} id={"contactar"} ref={end}>
                <Contacto />
            </div>

        </Layout>
        </>
    )
}

export default ContactoPage