import React from 'react';
import styled from 'styled-components';
import Link from "gatsby-link";
import theme from "styles/style.js";


export const DivBreadcrumb = styled.p`
  position: absolute;
  display: block;
  top: 2.5rem;
  right: 32.8px;
  //right: 22.5px; if not fixed
  width: 0.8em;
  height: 10em;
  word-wrap: break-word;
  text-align:center;
  text-transform: uppercase;
  font-size: ${theme.fsMenu};
  font-family: ${theme.light};
  z-index:  ${theme.z1};
  a { color: ${theme.textColor } }
  @media (max-width: ${theme.tablet} ){
    display: none;
  }
`

export const Breadcrumb = ( { to, children} ) => {
    return (
        <DivBreadcrumb>
            <Link to={to} >
                {children}
            </Link>
        </DivBreadcrumb>
    );
};

export default Breadcrumb;